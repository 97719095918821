<template>
    <app-page no-container>
        <template #page-header>
            <page-header name="Projects Overview" :loading="loading" v-model="search">
                <b-btn class="mb-2 mr-1" variant="secondary" @click="checkAllRobotsTXT">Check all robots.txt<i class="fas fa-robot ml-1"></i></b-btn>

            </page-header>
        </template>
        <b-overlay :show="loading" rounded="sm" class="p-3">
            <b-input-group class="w-50 mx-auto mb-3">
                <b-input-group-prepend>
                    <b-input-group-text>Compare environment variables with</b-input-group-text>
                </b-input-group-prepend>
                <b-select v-model="targetCluster">
                    <option v-for="cluster of $store.state.clusters" :value="cluster.id">{{ cluster.id }}</option>
                </b-select>
                <b-input-group-append>
                    <b-btn @click="compareEnvironmentVariables" variant="primary">Compare</b-btn>
                </b-input-group-append>
            </b-input-group>
            <table class="table-sticky-header m-auto" v-if="!isMobile()">
                <thead>
                <tr>
                    <th>Project</th>
                    <th>Main Domain</th>
                    <th>Robots.txt</th>
                    <th>Sitemap</th>
                    <th>Missing variables</th>
                    <th>Additional variables</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="project of filteredProjects">
                    <td><router-link :to="`/project/${project.name}`">{{ project.name }}</router-link></td>
                    <td>{{ project.main_domain }}</td>
                    <td>
                        <a :href="`https://${project.main_domain}/robots.txt`" target="_blank" v-if="project.main_domain"
                           :class="{'text-success': project.robotsTXT === robotsTXTCorrectValue, 'text-warning': project.robotsTXT !== robotsTXTCorrectValue, 'text-danger': project.robotsTXT === `Missing`}">
                            {{ project.robotsTXT }}
                        </a>
                        <span v-else class="text-success">N/A</span>
                    </td>
                    <td>
                        <span v-if="!project.sitemapURL?.includes(`http`)" :class="{'text-success': currentCluster.env !== `live`, 'text-warning': currentCluster.env === `live`}">{{ project.sitemapURL }}</span>
                        <a v-else :href="project.sitemapURL" target="_blank"  :class="{'text-success': currentCluster.env === `live`, 'text-warning': currentCluster.env !== `live`}">
                            {{ project.sitemapURL }}
                        </a>
                    </td>
                    <td>
                        <div v-for="envVar of project.missing_variables">{{ envVar.name }}</div>
                    </td>
                    <td>
                        <div v-for="envVar of project.additional_variables">{{ envVar.name }}</div>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="accordion" role="tablist" v-else>
                <b-card no-body class="mb-1" v-for="project in filteredProjects" :key="project.id">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle="project.id">{{ project.name }}</b-button>
                    </b-card-header>
                    <b-collapse :id="project.id" accordion="projects-accordion" role="tabpanel">
                        <b-card-body>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </b-overlay>
    </app-page>
</template>

<script>
import Network from "../../vue-components/helpers/Network.js";

export default {
    name: `projects-overview`,
    data() {
        return {
            loading: true,
            projects: [],
            search: ``,
            targetCluster: this.$store.state.cluster !== `dev` ? `dev` : `live`
        }
    },
    computed: {
        filteredProjects() {
            if (!this.search) {
                return this.projects;
            }

            return this.projects.filter(p => p.name.toLowerCase().includes(this.search.toLowerCase()))
        },
        currentCluster() {
            if (this.$store.state.clusters && this.$store.state.clusters.length > 0) {
                return this.$store.state.clusters.find(c => c.id === this.$store.state.cluster)
            } else {
                return {}
            }
        },
        robotsTXTCorrectValue() {
            return this.currentCluster.env === `live` ? `Allow` : `Disallow`
        }
    },
    activated() {
        this.unwatch = this.$store.watch(
            (state, getters) => getters.cluster,
            () => {
                this.init();
            }
        );

        this.init();
    },
    deactivated() {
        this.unwatch();
    },
    methods: {
        isMobile() {
            return window.screen.width <= 760;
        },
        async init() {
            this.loading = true;
            await this.getProjects();
            await Promise.allSettled([this.checkAllRobotsTXT(), this.compareEnvironmentVariables()]);
            this.loading = false;
        },
        async getProjects() {
            const resp = await Network.get(`/api/projects/${this.$store.state.cluster}`)
            this.projects = resp.data.filter(p => p.pods_count > 0);
        },
        async checkAllRobotsTXT() {
            for (const project of this.filteredProjects) {
                if (!project.main_domain) {
                    continue;
                }

                Network.post(`/api/projects/overview/check-domain-robots`, {
                    domain: project.main_domain
                }).then(resp => {
                    project.robotsTXT = resp.data.robotsTXT;
                    project.sitemapURL = resp.data.sitemapURL;
                    this.$forceUpdate();
                })
            }
        },
        async compareEnvironmentVariables() {
            for (const project of this.filteredProjects) {
                Network.post(`/api/projects/overview/compare`, {
                    name: project.name,
                    sourceCluster: this.$store.state.cluster,
                    targetCluster: this.targetCluster
                }).then(resp => {
                    project.missing_variables = resp.data.missing_variables;
                    project.additional_variables = resp.data.additional_variables;
                    this.$forceUpdate();
                })
            }
        }
    }
};
</script>


<style lang="scss" scoped>
.table-sticky-header {
    position: relative;
    border-collapse: separate;
    border-spacing: 0;

    $border: 1px solid black;

    th {
        position: sticky;
        top: 100px;
        border-top: $border;
        border-bottom: $border;
        border-right: $border;

        &:first-child {
            border-left: $border;
        }
    }

    td {
        border-bottom: $border;
        border-right: $border;

        &:first-child {
            border-left: $border;
        }
    }

    td, th {
        padding: 3px 5px;
        background-color: white;
        z-index: 5;
    }

    .checkbox-column {
        width: 100px;
    }
}
</style>
